import React from 'react'
import { submitForm } from '../utils/submitUtils'
import FormActions from '../components/FormActions'
import { Field, FormSection, reduxForm } from 'redux-form-react18'
import AlertMessage from '../components/AlertMessage'
import { useDispatch, useSelector } from 'react-redux'
import { required } from '../utils/validators'
import renderField from '../components/renderField'

const GoogleAnalyticsSettings = ({handleSubmit}) => {
    const dispatch = useDispatch()

    const { formData } = useSelector((state) => state?.user)
    const { googleAnalyticsInstalled, GA3Installed, GA4Installed } = useSelector((state) => state?.form?.wizard?.values?.wizard_options) || false

    const submit = (values) => {
        (submitForm(values, dispatch))
    };

    const googleAnalyticsInstalledOptions = googleAnalyticsInstalled !== "yes" ? true : false

    const disabledNext = googleAnalyticsInstalled === "yes" ? (GA3Installed === true || GA4Installed === true) ? false : true : false

  return (
    <form onSubmit={handleSubmit(submit)}>
        <h1 className='h1'>Add Google Analytics</h1>
        <h3 className='h3'>{formData && formData.url}</h3>
        <p className='simple-text'>
          This configuration will step you through adding both GA3 and GA4.
        </p>
        <p className='simple-text'>
          Ideally, include both so that Keylime Toolbox can provide uninterrupted historical data. Keylime Toolbox will use GA4 data beginning with the first data of the data and will use GA3 data for earlier data.
        </p>
        <p className="simple-text">
          If you'd like change the start date for GA4, just email us at <span className="underline text-blue-900">support@keylimetoolbox.com</span> and we can adjust the configuration.
        </p>
        <p className="simple-text mb-5">
          If your site doens't have both GA3 and GA4 implemented, provide information on whichever version is implemented.
        </p>
        <AlertMessage />
        <FormSection name="wizard_options">
            <Field
             name="googleAnalyticsInstalled"
             component={renderField}
             placeholder={"Google Analytics is installed on the site:"}
             id="radio1"
             type="radio"
             value="yes"
             validate={required}
            />
            <div className="flex flex-col ml-5 my-2 space-y-2">
                <Field
                 id="checkbox2"
                 name="GA4Installed"
                 autoFocus={false}
                 component={renderField}
                 placeholder="GA4 is installed on this site"
                 type="checkbox"
                 disabled={googleAnalyticsInstalledOptions}
                />
                <Field
                 id="checkbox1"
                 name="GA3Installed"
                 component={renderField}
                 placeholder="GA3 is installed on this site"
                 type="checkbox"
                 disabled={googleAnalyticsInstalledOptions}
                />
            </div>

            <Field
             name="googleAnalyticsInstalled"
             autoFocus={false}
             component={renderField}
             placeholder={"Google Analytics is not installed on the site:"}
             id="radio2"
             type="radio"
             value="no"
             validate={required}
            />
            </FormSection>
        <FormActions disableNext={disabledNext} />
    </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  })(GoogleAnalyticsSettings);