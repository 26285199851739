export const SET_USER = 'SET_USER';
export const GOOGLE_INIT = 'GOOGLE_INIT';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGOUT = 'GOOGLE_LOGOUT';
export const CLEAR_STATE = 'CLEAR_STATE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const KEYLIME_LOGOUT = 'KEYLIME_LOGOUT';
export const MANUAL_SITE_REQUEST = 'MANUAL_SITE_REQUEST';
export const REQUEST_SITES = 'REQUEST_SITES';
export const SET_TRANSITIONS = 'SET_TRANSITIONS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SET_DOMAIN_VARIATION_OPTIONS = 'SET_DOMAIN_VARIATION_OPTIONS';
export const SEND_DATA = 'SEND_DATA';
export const CHECK_GOOGLE_AUTHORIZED = 'CHECK_GOOGLE_AUTHORIZED';
export const GOOGLE_AUTHORIZED_LOGIN = 'GOOGLE_AUTHORIZED_LOGIN';
export const ADD_GA_USER = 'ADD_GA_USER';
export const TRY_ADD_USER = 'TRY_ADD_USER';
export const VALIDATE_REPORTING_GROUP = 'VALIDATE_REPORTING_GROUP';
export const ADD_REPORTING_GROUP = 'ADD_REPORTING_GROUP';
export const UPDATE_REPORTING_GROUP = 'UPDATE_REPORTING_GROUP';
export const REQUEST_ACCOUNT_SUMMARIES = 'REQUEST_ACCOUNT_SUMMARIES';
export const REQUEST_ACCOUNT_SUMMARIES_GA4 = 'REQUEST_ACCOUNT_SUMMARIES_GA4';