export function loadState() {
  try {
    const stateFromStorage = sessionStorage.getItem("state");
    return stateFromStorage ? JSON.parse(stateFromStorage) : undefined;
  } catch(error) {
    return undefined;
  }
}


export function saveState(state) {
  try {
    sessionStorage.setItem('state', JSON.stringify({user: state.user}));
  } catch(error) {
    // this block intentionally left blank
  }
}
