import { useEffect } from "react"
import { useSelector } from "react-redux"
import { history } from "../reducers/index"

export const useRequireAccessTokenProfiles = () => {
    const {user, navigator} = useSelector((state) => state)
  
    useEffect(() => {
      if (!user.accessToken) {
        history.replace({
          pathname: '/profiles',
          state: { nextPathname: navigator.location.pathname }
        });
      }
    }, [user.accessToken, navigator.location.pathname])
  }