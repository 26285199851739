import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Field, FormSection, reduxForm } from 'redux-form-react18'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { submitForm } from '../utils/submitUtils'

const SiteInfo = ({handleSubmit}) => {
    useRequireSelectedSite()
    const dispatch = useDispatch()
    const { formData } = useSelector((state) => state?.user)

    const submit = (values) => submitForm(values, dispatch);
  return (
    <form className='flex flex-col' onSubmit={handleSubmit(submit)}>
      <h1 className='h1'>Tell Us About</h1>
      <h3 className='h3'>{formData && formData.url}</h3>
      <AlertMessage />
        <FormSection name="wizard_options">
            <Field
              id="searchConsoleVerified"
              name="searchConsoleVerified"
              component={renderField}
              placeholder="I've verified this site in Google Search Console"
              type="checkbox"
            />
            <Field
              id="googleAnalyticsInstalled"
              name="googleAnalyticsInstalled"
              autoFocus={false}
              component={renderField}
              placeholder="I've installed Google Analytics on this site"
              type="checkbox"
            />
        </FormSection>
      <FormActions />
    </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  })(SiteInfo);