const isTest = process.env.NODE_ENV === 'test';

module.exports = {
  CLIENT_ID: isTest ? "" : process.env.REACT_APP_CLIENT_ID || "386068719980-92q6kprog4fmao7ms8cq94g987n379nj.apps.googleusercontent.com",
  EMAIL: "accounts@keylimetoolbox.com",
  APP_NAME: "Keylime Toolbox",
  APP_URL: process.env.REACT_APP_APP_URL || "https://app.keylime.io",
  DOMAIN_TLD: "keylime.io",
  SUPPORT: "support@keylimetoolbox.com",
  AIRBRAKE_PROJECT_ID: isTest ? "" : 122852,
  AIRBRAKE_PROJECT_KEY: isTest ? "" : "9bc3822d691a02502f5ce519119a447d"
};
