import { useSelector } from 'react-redux'
import { history } from '../reducers/index'

export const useRequireAccountSummaries = () => {
    const {navigator } = useSelector((state) => state)
    const { GA3Installed, GA4Installed } = useSelector((state) => state?.form?.wizard?.values?.wizard_options) || false

    if(GA3Installed  || GA4Installed) return;
    
      history.replace({
        pathname: '/searchconsolesites',
        state: { nextPathname: navigator.location.pathname }
      })
}
