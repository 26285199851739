import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form-react18'
import { APP_NAME, SUPPORT } from '../app.config'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import GoogleLogin from '../components/GoogleLogin'
import ManualProfiles from '../components/ManualProfiles'
import renderField from '../components/renderField'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { submitFailure, submitForm } from '../utils/submitUtils'
import { number } from '../utils/validators'

const Profiles = ({ handleSubmit }) => {
    useRequireSelectedSite()
    const dispatch = useDispatch()
    const { formData } = useSelector((state) => state?.user)
    const { googleAnalyticsUserAddedUniversal } = useSelector((state) => state?.form.wizard.values.wizard_options)
    const GA3Installed = useSelector((state) => state?.form?.wizard?.values?.wizard_options?.GA3Installed) || false
    const GA4Installed = useSelector((state) => state?.form?.wizard?.values?.wizard_options?.GA4Installed) || false

    const selectProfile = () => {
      dispatch(change('wizard','profileSelect',null));
      dispatch(change('wizard','ga_account_id',null));
    };

    const GA4Configuration = googleAnalyticsUserAddedUniversal === "yes" ? false : true

    const submit = (values) => submitForm(values, dispatch);

  return (
    <form  className='flex flex-col' onSubmit={handleSubmit(submit)}>
      <h1 className='h1'>Add Google Analytics</h1>
      <h3 className='h3'>{formData && formData.url}</h3>
      <p className='simple-text'>Add the {GA4Configuration ? "Property ID" : "View ID"} that you want to import into your {APP_NAME} account. We generally recommend adding the default/raw view.
        If you&#39;re unsure which view is best, just email us at <a className="navbar-link" href={`mailto:${SUPPORT}`} tabIndex="-1">{SUPPORT}</a> and we&#39;ll help you out.</p>
      <p className='simple-text'>If you can&#39;t add this data now, you can come back to your account and add it later.</p>
      <AlertMessage/>
      <fieldset>
          { GA4Installed &&
            <Field name={"ga_property_id"}
              component={renderField}
              type="text"
              label={"Property ID"}
              validate={number}
              autoFocus={false}
              onChange={(e) => selectProfile(e)}
            />
          }
          <div className="mt-5"/>
          {GA3Installed &&
            <Field name={"ga_view"}
              component={renderField}
              type="text"
              label={"View ID"}
              validate={number}
              autoFocus={false}
              onChange={(e) => selectProfile(e)}
            />
          }
        <Field name="profileSelect"
          component={renderField}
          type="hidden"
        />
        <Field name="ga_property_id"
          component={renderField}
          type="hidden"
        />
        <Field name="ga_account_id"
          component={renderField}
          type="hidden"
        />
        <ManualProfiles initialValues={formData} GA4Configuration={GA4Configuration} />
        <details>
          <summary>Learn how to add users in GA4 and GA3:</summary>
          <ul>
            <li className="flex">
              <div className="text-base mr-2">•</div>
              <a href={"https://support.google.com/analytics/answer/9305788"} target="_blank" rel="noopener noreferrer" className={"link mt-[3px]"}>GA4</a>
            </li>
            <li className="flex">
              <div className="text-base mr-2">•</div>
              <a href={"https://support.google.com/analytics/answer/1009702"} target="_blank" rel="noopener noreferrer" className={"link mt-[3px]"}>GA3</a>
            </li>
          </ul>
        </details>
        <p className="help-block">
          <GoogleLogin
            tag="a"
            buttonText="Sign in with a Google Account associated with Google Analytics"
            className={'btn btn-link btn-no-pad'}
          />
        </p>
      </fieldset>
      <FormActions />
    </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
    onSubmitFail: submitFailure(['ga_view'])
  })(Profiles);
