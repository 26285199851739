import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import user from './userReducer';
import { reducer as formReducer } from 'redux-form-react18';
import { createRouterReducer } from '@lagunovsky/redux-react-router';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  user,
  form: formReducer,
  navigator: createRouterReducer(history),
});

export default rootReducer;
