import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form-react18'

const RenderCheckboxGroup = ({input, options, dispatch}) => {
    const allChecked = () => {
        let selectedUrls;
        selectedUrls = [];
        if (input.value) selectedUrls = input.value;
        return options?.length > selectedUrls?.length;
      };

      
    const handleSelectAll = (e) => {
        e.preventDefault();
        let urls;
        if (allChecked()) {
          urls = options;
        } else {
          urls = [];
        }
        dispatch(change('wizard', input.name, urls));
        e.target.blur();
      };
  return (
    <div>
    <button className="btn btn-link" onClick={handleSelectAll} tabIndex="-1">
      { options?.length > 1 && allChecked() ?
        "Check All"
        :
        options?.length > 1 &&
        "Uncheck All"
      }
    </button>
    <div className="checkbox-group well mt-5 space-y-2">
      { options?.map((option, index) => (
        <div className="checkbox" key={index}>
          <label>
            <input type="checkbox"
              name={`${input.name}[${index}]`}
              value={option}
              checked={input.value.indexOf(option) !== -1}
              onChange={event => {
                const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option);
                  } else {
                    newValue.splice(newValue.indexOf(option), 1);
                  }
                  return input.onChange(newValue);
                   }}
            />
            <span className="my-auto ml-2">
              {option}
            </span>
          </label>
        </div>))
      }
    </div>
  </div>
  )
}

export default connect(() => { return {}; })(RenderCheckboxGroup);