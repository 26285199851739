import * as types from '../constants/actionTypes';
import initialState from './initialState';
import {notify} from '../utils/airbrake';

const handleGoogleError = (user, type, response) => {
  if (response.error && response.error.message) {
    if (response.error.code && response.error.code === 403) {
      if (type === `${types.ADD_GA_USER}_REJECTED`) {
        return null;
      } else {
        if (type === `${types.REQUEST_ACCOUNT_SUMMARIES}_REJECTED`) {
          return "You don't have Google Analytics with that account. Enter it manually or try signing in with another Google account.";
        }
      }
    }
    return response.error.message;
  } else {
    if (response.error !== 'popup_closed_by_user') {
      notify({
        error: response.error,
        params: {action: {type: type}, response: response},
        session: {...user},
      });
    }
  }

  if (response.error === 'timeout') {
    return "We're having trouble connecting with Google at the moment, but we'll keep trying...";
  }

  return "The sign up process through Google was not completed. This could be because Google’s system isn’t available right now or because you canceled the process. You can start the Google sign in process again or set up the account manually.";
};

const handleKeylimeError = (user, type, response) => {
  let msg;
  if (response && response.error) {
    msg = response.error;
    if (response.status > 499) {
      notify({
        error:       { response },
        params:      { action: {type: type} },
        session:     {...user},
      });
    }
  } else {
    if (response.errors) {
      let error;
      if (response.errors[0].sites[0].errors.base) {
        error = response.errors[0].sites[0].errors.base ||
                response.errors[0].sites[0].errors.base[0];
      } else if (response.errors[0].sites[0].errors.url) {
        error = response.errors[0].sites[0].errors.url ||
                response.errors[0].sites[0].errors.url[0];
      }
      msg = `${response.msg} ${(error)}`;
    }
  }
  return msg;
};


export default function userReducer(state = initialState.user, action) {

  switch (action.type) {

    case `${types.SET_USER}_FULFILLED`:
      return {...state, returnUrl: action.payload.returnUrl, authenticityToken: action.payload.authenticityToken, name: action.payload.name, group: action.payload.group, groupSlug: action.payload.groupSlug};

    case `${types.SET_USER}_REJECTED`:
      return {...state, returnUrl: action.payload.returnUrl};

    case `${types.GOOGLE_INIT}_PENDING`:
      return {...state, googleDisabled: true, error: null, scError: null, gaError: null};

    case `${types.GOOGLE_INIT}_FULFILLED`:
      return {...state, googleDisabled: false};

    case `${types.GOOGLE_INIT}_REJECTED`:
      return {...state, error: handleGoogleError(state, action.type, action.payload)};

    case `${types.GOOGLE_LOGIN}_PENDING`:
      return {...state, googleDisabled: true};

    case `${types.GOOGLE_LOGIN}_FULFILLED`:
      return {...state, ...action.payload, sites: null, accountSummaries: null, error: null, googleDisabled: false, scError: null, gaError: null};

    case `${types.MANUAL_SITE_REQUEST}_FULFILLED`:
      return {...state, accessToken: null, sites: null, accountSummaries: null, formData: {}};

    case `${types.GOOGLE_LOGIN}_REJECTED`:
      return {...state, error: handleGoogleError(state, action.type, action.payload), scError: null, gaError: null, googleDisabled: false};

    case `${types.KEYLIME_LOGOUT}_REJECTED`:
      return {...state, error: "There was a problem with your logout."};

    case `${types.REQUEST_SITES}_FULFILLED`:
      return {...state, sites: action.payload, scError: null};

    case `${types.REQUEST_SITES}_REJECTED`:
      return {...state, scError: handleGoogleError(state, action.type, action.payload), error: null};

    case `${types.REQUEST_ACCOUNT_SUMMARIES}_FULFILLED`:
      return {...state, accountSummaries: action.payload && action.payload.items, gaError: null};

    case `${types.REQUEST_ACCOUNT_SUMMARIES}_REJECTED`:
      return {...state, gaError: handleGoogleError(state, action.type, action.payload), error: null};

    case `${types.SET_TRANSITIONS}_FULFILLED`:
      return {...state, ...action.payload, error: null};

    case `${types.SUBMIT_FORM}_PENDING`:
      return {...state, error: null};

    case `${types.SUBMIT_FORM}_FULFILLED`:
      return {...state, formData: action.payload};

    case `${types.GOOGLE_AUTHORIZED_LOGIN}_FULFILLED`:
      return {...state, ...action.payload};

    case `${types.GOOGLE_AUTHORIZED_LOGIN}_REJECTED`:
      return {...state, error: null, gaError: handleGoogleError(state, action.type, action.payload)};

    case `${types.ADD_GA_USER}_REJECTED`:
      return {...state, error: null, gaError: handleGoogleError(state, action.type, action.payload)};

    case `${types.SET_DOMAIN_VARIATION_OPTIONS}_FULFILLED`:
      return {...state, ...action.payload};

    case `${types.CLEAR_ERROR}_FULFILLED`:
      return {...state, error: null, changingLocation: false};

    case`${types.CLEAR_STATE}_FULFILLED`:
      return state = {returnUrl: action.payload, googleDisabled: true};

    case`${types.CHANGE_LOCATION}_FULFILLED`:
      return {...state, changingLocation: true};

    case `${types.VALIDATE_REPORTING_GROUP}_REJECTED`:
      return {...state, error: handleKeylimeError(state, action.type, action.payload)};

    case `${types.ADD_REPORTING_GROUP}_FULFILLED`:
      return {...state, groupSlug: action.payload[0].slug};

    case `${types.ADD_REPORTING_GROUP}_REJECTED`:
      return {...state, error: handleKeylimeError(state, action.type, action.payload)};

    case `${types.UPDATE_REPORTING_GROUP}_REJECTED`:
      return {...state, error: handleKeylimeError(state, action.type, action.payload)};

    case `${types.REQUEST_ACCOUNT_SUMMARIES_GA4}_FULFILLED`:
        return {...state, accountSummariesG4: action.payload && action.payload.accountSummaries
          , gaError: null};
  
    case `${types.REQUEST_ACCOUNT_SUMMARIES_GA4}_REJECTED`:
        return {...state, gaError: handleGoogleError(state, action.type, action.payload), error: null};

    default:
      return state;
  }
}
