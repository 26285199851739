import 'whatwg-fetch';
import omit from 'lodash/omit';
import {EMAIL} from '../app.config';

const GOOGLE = 'https://www.googleapis.com';
const GOOGLEGA4 = 'https://analyticsadmin.googleapis.com';

let AUTHENTICITY_TOKEN;

const sendData = function(formData, domainMatches) {
  const domainVariations = formData.domainVariations;
  const fields = ['siteSelect', 'profileSelect', 'domainVariations'];
  const data = omit(formData, fields);

  let sendData = {};
  sendData["0"] = data;

  domainVariations && domainVariations.map((url, index) => {
    return sendData[`${index+1}`] = {"url": url};
  });
  return {gsc_properties_attributes: sendData, domain_variations: domainMatches||null};
};

export const updateSessionMaybe = function({user}) {
  if (AUTHENTICITY_TOKEN !== user.authenticityToken) {
    AUTHENTICITY_TOKEN = user.authenticityToken;
  }
};

export const googleAuthorized = function(accessToken) {
  return new Promise(
    function (resolve, reject) {
      googleFetch(`/oauth2/v3/tokeninfo?access_token=${accessToken}`)
        .then(() => resolve({accessToken: accessToken})).catch((response) => reject(response));
    }
  );
};

export const getSites = function(accessToken) {
  return googleFetch(`/webmasters/v3/sites`, accessToken);
};

export const getAccountSummaries = function(accessToken) {
  return googleFetch(`/analytics/v3/management/accountSummaries`, accessToken);
};

export const getAccountSummariesGA4 = function(accessToken) {
  return googleFetchGA4(`/v1beta/accountSummaries`, accessToken)
}

export const addUser = function(accessToken, account, property, profile, formValues) {
  const { profileSelect, profileSelectGA3 } = formValues

  const ga4Options = JSON.parse(profileSelect.value)
  const ga3Options = JSON.parse(profileSelectGA3.value)

  const dataGA4 =
    {
      "directRoles": [
        "predefinedRoles/viewer"
      ],
      "emailAddress": EMAIL
    };

    const data =
    {
      "permissions": {
        "local": [ "READ_AND_ANALYZE" ]
      },
      "userRef": {
        "email": EMAIL,
        "kind": "analytics#userRef"
      }
    };

  // Add user link for UA accounts
  if(ga3Options.ga_view){ googleFetch(`/analytics/v3/management/accounts/${ga3Options.ga_account_id}/webproperties/${ga3Options.ga_property_id}/profiles/${ga3Options.ga_view}/entityUserLinks`, accessToken, data, 'POST')};

  // Add user link for GA accounts
  if(ga4Options.ga_property_id) { googleFetchGA4(`/v1alpha/accounts/${ga4Options.ga_account_id}/userLinks`, accessToken, dataGA4, 'POST')};
};

export const keylimeSignout = function(returnUrl) {
  return keylimeFetch(`${returnUrl}/users/sign_out`, {"_method": "DELETE"}, 'DELETE');
};

export const validateGroup = function(returnUrl, url) {
  return keylimeFetch(`${returnUrl}/site_groups/validate?url=${encodeURIComponent(url)}`);
};

export const addGroup = function(returnUrl, formData, domainMatches) {
  const data = {site_groups: {"0": sendData(formData, domainMatches)}};
  // const gaView = data.site_groups[0].gsc_properties_attributes[0].ga_view
  // if (gaView !== null && gaView !== undefined && gaView !== '') {
  //   delete data.site_groups[0].gsc_properties_attributes[0].ga_property_id
  // }
  return keylimeFetch(`${returnUrl}/site_groups.json`, data, 'POST');
};

export const updateGroup = function(returnUrl, groupSlug, formData, domainMatches) {
  const data = {site_group: sendData(formData, domainMatches)};
  return keylimeFetch(`${returnUrl}/site_groups/${encodeURIComponent(groupSlug)}.json`, data, 'POST');
};


const getGoogleRequestConfig = (apiUrl, accessToken , data, method , baseUrl) => {
  const headers = {};
  headers['accept'] = 'application/json';
  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if (data) headers['Content-Type'] = 'application/json';
  const config = {
    method: method,
    headers: headers
  };
  if (data) {
    config.body = JSON.stringify(data);
  }
  return {config, apiUrl: `${baseUrl}${apiUrl}`};
}

const googleFetchGA4 = function(api, accessToken = null, data = null, method = 'GET') {
  const {config, apiUrl} = getGoogleRequestConfig(api, accessToken, data, method, GOOGLEGA4)
  return apiFetch(apiUrl, config);
};

const googleFetch = function(api, accessToken = null, data = null, method = 'GET') {
  const {config, apiUrl} = getGoogleRequestConfig(api, accessToken, data, method, GOOGLE)
  return apiFetch(apiUrl, config);
};

const keylimeFetch = function(api, data = null, method = 'GET') {
  const headers = {};
  if (method === 'DELETE') {
    method = 'POST';
    headers['X-HTTP-Method-Override'] = 'DELETE';
  } else {
    headers['accept'] = 'application/json';
  }
  if (data) headers['Content-Type'] = 'application/json';
  const config = {
    credentials: 'include',
    method: method,
    headers: headers
  };
  if (data) {
    data["authenticity_token"] = AUTHENTICITY_TOKEN;
    config.body = JSON.stringify(data);
  }
  return apiFetch(api, config);
};

const apiFetch = function(api, config) {
  return fetch(api, config).then(response => {
    if (response.headers.get('Content-Type').startsWith('application/json')) {
      return response.json().then(json => {
        if (response.status < 300) {
          return json;
        } else {
          json["status"] = response.status;
          return Promise.reject(json);
        }
      });
    }
    return response.text().then(text => {
      if (response.status < 300) {
        return text;
      } else {
        return Promise.reject({error: text, status: response.status});
      }
    });
  });
};
