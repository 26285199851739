import React from 'react'
import { useSelector } from 'react-redux'

const AlertMessage = () => {
    const { success, error, scError, gaError } = useSelector((state) => state.user)
    const { pathname } = useSelector((state) => state.navigator.location)

    const alertError = error ? error : pathname === '/gaprofiles' && gaError ? gaError : pathname === '/searchconsolesites' && scError ? scError : null;
    
    return (
        alertError ?
          <div className="border border-keylime-red-3 text-keylime-red-1 bg-keylime-red-2 rounded p-4" role="alert">{alertError}</div>
          :
          success ?
            <div className="alert alert-success" role="alert">{success}</div>
            :
            null
      );
}

export default AlertMessage