import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { googleInitialize } from '../actions/userActions'
import Footer from './Footer'
import Navbar from './Navbar'

const Layout = ({children}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(googleInitialize())
    }, [dispatch])

  return (
    <>
      <div className='mb-16'>
        <Navbar />
      </div>
      <div className="flex px-4 md:ml-[14%] max-w-[800px] flex-col pb-[52px]">
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout 