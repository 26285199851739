import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Field, FormSection, reduxForm } from 'redux-form-react18'
import { APP_NAME } from '../app.config'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { submitForm } from '../utils/submitUtils'

const SearchConsoleAdd = ({handleSubmit}) => {
    useRequireSelectedSite()
    const dispatch = useDispatch()
    const { formData } = useSelector((state) => state?.user)

    const submit = (values) => submitForm(values, dispatch);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h1 className='h1'>Add Your Site to Google Search Console</h1>
      <p className='text-base mt-2 mb-5'>{APP_NAME} uses data from Google Search Console. Follow the steps below to add your site.</p>
      <p className='text-base mt-2 mb-5'><em>If you can&#39;t complete these steps now we will provide them in a checklist later.</em></p>
      <h3 className='h3 my-2'>{formData && formData.url}</h3>
      <AlertMessage />
      <FormSection name="wizard_options">
        <Field
          name="loginSearchConsole"
          component={renderField}
          placeholder={`Log in to <a className='link' href="https://www.google.com/webmasters/" target="_blank">Google Search Console</a> with your Google Account`}
          type="checkbox"
        />
        <ul className='ml-5 my-2'>
          <li className='flex items-center h-fit'>
            <div className="mr-1">•</div>
            <a className='link flex mt-1' href="https://www.google.com/webmasters/" target="_blank" rel="noopener noreferrer">Access Google Search Console here</a></li>
          <li className='flex items-center h-fit'>
            <div className="mr-1">•</div>
            <a className='link flex mt-1' href="https://support.google.com/webmasters/#topic=3309469" target="_blank" rel="noopener noreferrer">Learn more about creating a Google Search Console account here</a></li>
        </ul>
        <Field
          name="addProperty"
          component={renderField}
          placeholder={`In Google Search Console, click <strong>Add a Property</strong>, then add ${formData && formData.url}`}
          autoFocus={false}
          type="checkbox"
        />
        <Field
          name="ownershipInstructions"
          component={renderField}
          placeholder="Follow the instructions to verify ownership of the site"
          autoFocus={false}
          type="checkbox"
        />
      </FormSection>
      <FormActions />
    </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  })(SearchConsoleAdd);