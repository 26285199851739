import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  Field,
  FormSection,
  reduxForm } from 'redux-form-react18'
import { APP_NAME, EMAIL } from '../app.config'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
  import {submitFailure, submitForm} from '../utils/submitUtils'
import { required } from '../utils/validators'

const SearchConsole = ({handleSubmit}) => {
  useRequireSelectedSite()
  const dispatch = useDispatch()

  const initialValues = useSelector((state) => state?.user?.formData)
  const submit = (values) => {submitForm(values, dispatch)};
  
  return (
    <form className='flex flex-col' onSubmit={handleSubmit(submit)}>
      <h1 className='h1'>Add <span className="break-all text-keylime-green-1 text-[36px] font-normal box-border leading-[1.1]">{EMAIL}</span> as a user of your site in Google Search Console</h1>
      <p className='simple-text'>This enables {APP_NAME} to use Google&#39;s API to import data.</p>
      <h3 className='h3'>{initialValues && initialValues.url}</h3>
      <AlertMessage />
      <FormSection name="wizard_options">
        <Field
          name="searchConsoleUserAdded"
          component={renderField}
          placeholder={`I've added <code>${EMAIL}</code> as a user to the site`}
          id="radio1"
          type="radio"
          value="yes"
          validate={required}
        />
        <Field
          name="searchConsoleUserAdded"
          autoFocus={false}
          component={renderField}
          placeholder={`I'll add <code>${EMAIL}</code> later (we'll add this step to your setup checklist)`}
          id="radio2"
          type="radio"
          value="no"
          validate={required}
        />
      </FormSection>

      <details className='text-[15px] mt-7'>
        <summary >Show me how to add a user in Google Search Console</summary>
        <ol className='ml-6 mt-2'>
          <li className='flex'>
            <p>1.</p>
            <p className="ml-1">
            Log into 
              <a className='link ml-1' href="https://www.google.com/webmasters/" target="_blank" rel="noopener noreferrer">
              Google Search Console</a>.
              (Read 
              <a className='link ml-1' href="https://support.google.com/webmasters/answer/2453966" target="_blank" rel="noopener noreferrer">
                Google&#39;s help documentation here</a>.)
            </p>
          </li>
          <li className='flex mt-1'>
            <p>2.</p>
            <p className="ml-1">
              From your list of sites, click <strong>Manage Property</strong> to the right of the site name, then choose <strong>Add or Remove Users</strong>.
            </p>
          </li>
          <li className='flex mt-1'>
            <p>3.</p>
            <p className='ml-1'>Click the <strong>Add a New User</strong> button and add <code>{EMAIL}</code> as a restricted user.</p>
          </li>
        </ol>
      </details>
      <FormActions />
    </form>
  )
}

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,        // <------ preserve form data
  forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  onSubmitFail: submitFailure(['searchConsoleUserAdded'])
})(SearchConsole);