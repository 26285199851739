import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, FormSection, reduxForm } from 'redux-form-react18'
import { EMAIL } from '../app.config'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import useCheckAccessToken from '../hooks/useCheckAccessToken'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { submitFailure, submitForm } from '../utils/submitUtils'

const GoogleAnalytics = ({handleSubmit}) => {
    useCheckAccessToken()
    useRequireSelectedSite()

    const dispatch = useDispatch()
    const submit = (values) => submitForm(values, dispatch);

  return (
    <form className='max-w-[810px] flex flex-col' onSubmit={handleSubmit(submit)}>
      <h1 className='h1 max-w-[710px] break-all'>Add {EMAIL} as a user of the property in Google Analytics</h1>
      <p className='simple-text'>This configuration will step you through adding both GA3 and GA4.</p>
      <p className='simple-text'>Ideally include both so that Keylime Toolbox can provide uninterrupted historical Google Analytics data. </p>
      <p className='simple-text'>Keylime Toolbox will use GA4 beginning with the first date of data and will use GA3 for earlier data.</p>
      <p className='simple-text'>If you'd like you change the start date for GA4 data, just email us at support@keylimetoolbox.com and we can adjust the configuration.</p>
      <p className='simple-text'>If your site doesn't have both GA3 and GA4 implemented, provide information on whichever version is implemented.</p>
    
      <div className="mt-5">
        <FormSection name="wizard_options">
          <div className="space-y-2">
            <Field
              id="checkbox2"
              name="GA4Installed"
              autoFocus={false}
              component={renderField}
              placeholder="GA4 is installed on this site"
              type="checkbox"
              />
              <Field
              id="checkbox1"
              name="GA3Installed"
              component={renderField}
              placeholder="GA3 is installed on this site"
              type="checkbox"
            />
          </div>
        </FormSection>
      </div>
    <FormActions />
  </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
    onSubmitFail: submitFailure(['googleAnalyticsUserAdded'])
  })(GoogleAnalytics);