import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, FormSection, reduxForm } from 'redux-form-react18'
import { APP_NAME, EMAIL } from '../app.config'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import { useRequireAccessTokenProfiles } from '../hooks/useRequireAccessTokenProfiles'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { submitFailure, submitForm } from '../utils/submitUtils'
import { required } from '../utils/validators'

const GAAddUser = ({handleSubmit}) => {
    useRequireSelectedSite()
    useRequireAccessTokenProfiles()
    const dispatch = useDispatch()

    const submit = (values) => submitForm(values, dispatch)

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(submit)}>
    <h1 className='h1'>Authorize access in Google Analytics</h1>
    <p className='text-base mt-2 mb-5'>{APP_NAME} uses the Google Analytics API to import data into your account.</p>
    <AlertMessage />
    <FormSection name="wizard_options">
      <Field
        name="googleAnalyticsUserAdded"
        component={renderField}
        placeholder={`Authorize ${APP_NAME} to add <code>${EMAIL}</code> as a user of the view.`}
        id="radio1"
        type="radio"
        value="yes"
        validate={required}
      />
      <Field
        name="googleAnalyticsUserAdded"
        autoFocus={false}
        component={renderField}
        placeholder={`I'll manually add <code>${EMAIL}</code> as a user later (we'll add this step to your setup checklist)`}
        id="radio2"
        type="radio"
        value="no"
        validate={required}
      />
    </FormSection>
    <FormActions /> 
  </form>
  )
}

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,        // <------ preserve form data
  forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  onSubmitFail: submitFailure(['googleAnalyticsUserAdded'])
})(GAAddUser);