import configureStore from './configureStore';
import {loadState, saveState} from './storedState';
import throttle from 'lodash/throttle';
import {updateSessionMaybe} from '../api/ApiManager';

const initializeStore = () => {
  const store = configureStore(loadState());
  // subscribe to change into the store with storedStated file
  store.subscribe(throttle(() => {
    return saveState(store.getState()
    )}, 500));
  store.subscribe(() => {
    return updateSessionMaybe(store.getState())});

  return store;
};

export default initializeStore;
