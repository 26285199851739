import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form-react18'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import RenderCheckboxGroup from '../components/RenderCheckboxGroup'
import { useRequireDomainVariationOptions } from '../hooks/useRequireDomainVariationOptions'
import { submitForm } from '../utils/submitUtils'

const DomainVariations = ({handleSubmit}) => {
  useRequireDomainVariationOptions()
  const dispatch = useDispatch()
    const {domainVariationOptions, formData} = useSelector((state) => state?.user)
    const submit = (values) => (submitForm(values, dispatch));
  return (
    <form onSubmit={handleSubmit(submit)}>
    <h1 className='h1'>Add Domain Variations</h1>
    <h3 className='h3'>{formData && formData.url}</h3>
    <AlertMessage />
    {domainVariationOptions?.length > 1 ?
      <p>We&#39;ve found the following domain variations. Would you like to aggregate data from these variations into your site&#39;s reports? Learn more</p>
      :
      <p>We&#39;ve found the following domain variation. Would you like to aggregate data from this variation into your site&#39;s reports? Learn more</p>
    }
    <Field
      name="domainVariations"
      component={RenderCheckboxGroup}
      options={domainVariationOptions}
    />
    <FormActions />
  </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  })(DomainVariations);