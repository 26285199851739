import React from 'react';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { Route, Routes } from 'react-router';
import HomePage from './pages/HomePage'
import SearchConsoleSites from './pages/SearchConsoleSites'
import SearchConsole from './pages/SearchConsole'
import NotFoundPage from './pages/NotFoundPage'
import { history } from './reducers'
import DomainVariations from './pages/DomainVariations'
import { useAuthAccount } from './hooks/useAuthAccount'
import GAProfiles from './pages/GAProfiles'
import GAAddUser from './pages/GAAddUser'
import Sites from './pages/Sites'
import Siteinfo from './pages/SiteInfo'
import SearchConsoleAdd from './pages/SearchConsoleAdd'
import Profiles from './pages/Profiles'
import GoogleAnalytics from './pages/GoogleAnalytics'
import GoogleAnalyticsSettings from './pages/GoogleAnalyticsSettings'
import Layout from './components/Layout'

function App() {
  useAuthAccount();
  const routerSelector = (state) => state.navigator;

  return (
    <div className="App screen-layout">
      <Layout>
        <ReduxRouter history={history} routerSelector={routerSelector}>
          <Routes>
              <Route path={'/'} element={<HomePage />}/>
              <Route path={'/searchconsolesites'} element={<SearchConsoleSites />} />
              <Route path={'/settingsGoogleAnalytics'} element={<GoogleAnalyticsSettings />} />
              <Route path={'/sites'} element={<Sites />}/>
              <Route path={'/domainvariations'} element={<DomainVariations />}/>
              <Route path="/siteinfo" element={<Siteinfo />} />

              {/*require Selected Site  */}
              <Route path="/searchconsoleadd" element={<SearchConsoleAdd />} />
              <Route path={'/searchconsole'} element={<SearchConsole />}/>
              <Route path="/googleanalytics" element={<GoogleAnalytics />}  />
              <Route path="/profiles" element={<Profiles />} />
              <Route path={'/gaprofiles'} element={<GAProfiles />}/>     
              <Route path="/gaadduser" element={<GAAddUser />}/>

              <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ReduxRouter>
      </Layout>
    </div>
  );
}

export default App;
