import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change,
         Field,
         reduxForm,
         SubmissionError } from 'redux-form-react18'
import { manualSiteRequest, validateReportingGroup } from '../actions/userActions'
import { APP_NAME } from '../app.config'
import GoogleLogin from '../components/GoogleLogin'
import renderField from '../components/renderField'
import renderSelect from '../components/renderSelect'
import {submitFailure, submitForm} from '../utils/submitUtils'
import {required, url} from '../utils/validators';
import FormActions from '../components/FormActions'
import { Link } from 'react-router-dom'
import { history } from '../reducers/index'
import { useRequireAccessTokenSites } from '../hooks/useRequireAccessTokenSites'
import AlertMessage from '../components/AlertMessage'

const SearchConsoleSites = ({handleSubmit }) => {
    useRequireAccessTokenSites()
    const dispatch = useDispatch();
    const {authEmail, returnUrl, group, sites} = useSelector((state) => state?.user)
    let siteOptions = [];
    sites && sites.map((url) => siteOptions.push({value: url, label: url}));

    const changeFieldValue = (field, value) => {
        dispatch(change('wizard', field, value))
    }

    const selectSite = (selected) => {
        const url = selected.value ? selected.value : null;
        changeFieldValue('url',url);
    };

    const submit = (values) => {
        if (sites && !values.siteSelect){
            throw new SubmissionError({ siteSelect: 'Required', _error: 'Site is required.' });
        } else {
            dispatch(validateReportingGroup(values.url)).then(() => 
                submitForm(values, dispatch)
                ).catch((error) => {
                    if (error.status > 400) 
                    return history.push(`${returnUrl}/users/sign_in`)
            })
        }
    }
    const manualClick = (event) => {
        if (event) event.preventDefault();
        changeFieldValue('url',null);
        changeFieldValue('siteSelect',null);
        dispatch(manualSiteRequest()).then(() => {
          return history.push('/sites');
        });
      };

  return (
    <form onSubmit={(handleSubmit(submit))}>
      {group ?
        <h1 className='h1 mb-2'>Add a Site to Aggregate with Data from {group}</h1>
        :
        <h1 className='h1 mb-2'>Add a Site To Your {APP_NAME} Account</h1>
      }
      <AlertMessage />
      {sites ?
        <p className='text-[15px] mb-2'>Choose a site from those verified in your Google Account.</p>
        :
        <p className='text-[15px] mb-2'>Add the site as it resolves (for instance, with http or https, with www or without).</p>
      }
      {sites ?
        <fieldset>
          <Field name="siteSelect"
                 component={renderSelect}
                 options={siteOptions}
                 validate={[required, url]}
                 onChange={(e) => selectSite(e)}
          />
          <Field name="url"
                 component={renderField}
                 type="hidden"
          />
          <p className="text-keylime-gray-3 text-[15px]">If the site resolves multiple ways, you&#39;ll have a chance to add variations next.</p>
        </fieldset>
        :
        <fieldset>
          <Field name="url"
                 tabIndex="0"
                 component={renderField}
                 placeholder="https://www.example.com"
                 type="text"
                 Label="Site URL"
                 validate={[required, url]}
                 onChange={(e) => selectSite(e)}
          />
          <Field name="siteSelect"
                 component={renderField}
                 type="hidden"
          />
        </fieldset>
      }
      <p className="help-block text-base text-keylime-gray-3 mt-5 text-[15px]">
        SIGNED IN: {authEmail}
        <br/>
        {sites && "Don't see your site listed? " }
        <GoogleLogin
          tag="a"
          text="Sign in with Google to another account"
        />
        {sites &&
          <span>
            { " " }
            or enter a site <Link className="link" to="/sites" onClick={(e) => manualClick(e)}>manually</Link>.
          </span>
        }
      </p>
      <FormActions />
    </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
    onSubmitFail: submitFailure(['siteSelect'])
  })(SearchConsoleSites);