import React from 'react'

const renderField = ({input, placeholder, type, autoFocus, id, disabled, label, meta: {touched, error, warning}}) => (
    type === 'checkbox' ?
    <div className={touched && error ? `${type} has-error` : touched && warning ? `${type} has-warning` : `${type}`}>
      <label>
        <input
          type={type}
          id={id}
          autoFocus={autoFocus}
          disabled={disabled}
          checked={input.value}
          onChange={() => input.onChange(!input.checked)}
        />
        <span
          className="ml-2 text-[15px]"
          dangerouslySetInnerHTML={createMarkup(placeholder)}
        />
      </label>
    </div>
    : type === 'radio' ?
      <div className={`${touched && error ? `${type} has-error` : touched && warning ? `${type} has-warning` : `${type}`} flex flex-col py-1`}>
        <label className='flex items-center'>
          <input {...input} type={type} disabled={disabled} autoFocus={autoFocus} id={id} className='mr-2 h-3 w-3' />
          <p
            className='text-[15px]'
            dangerouslySetInnerHTML={createMarkup(placeholder)}
          />
        </label>
        <span className="text-keylime-red-1 ml-4">{touched && (error || warning)}</span>
      </div>
    : type === 'hidden' ?
      <input {...input} type={type} />
    :
      <div className={`${touched && error ? 'form-group has-error' : touched && warning ? 'form-group has-warning' : 'form-group'} flex flex-col`}>
        { label && <label htmlFor={id}>{label}</label> }
        <input
          className="form-control text-black p-2 border-2 rounded-md text-[15px]"
          {...input}
          type={type}
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder}
        />
        <span className="text-keylime-red-1 ml-4">{touched && (error || warning)}</span>
      </div>
)

export default renderField

const createMarkup = (label) => {
    return {__html: label};
  };