import { useSelector } from 'react-redux'
import {history} from '../reducers/index'


export const useRequireSelectedSite = () => {
    const {user, navigator } = useSelector((state) => state)
    if (!user.formData?.url) {
      let path = '/sites';
      if (user.accessToken) path = '/searchconsolesites';
      history.replace({
        pathname: path,
        state: {nextPathname: navigator.location.pathname}
      });
    }
  };