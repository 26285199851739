import {createStore, compose, applyMiddleware} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
// TODO uncomment once it's included
// import airbrakeMiddleware from '../utils/airbrake';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'

const routerMiddleware = createRouterMiddleware();
export const middlewaresDev = [
  // Add other middleware on this line...
  
  // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
  reduxImmutableStateInvariant(),
  
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunk,
  // airbrakeMiddleware(),
  promiseMiddleware,
  routerMiddleware,
];

function configureStoreProd(initialState) {
  const middlewares = [
    // Add other middleware on this line...
    
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    // airbrakeMiddleware(),
    promiseMiddleware,
  ];

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middlewares)
    )
  );
}

function configureStoreDev(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewaresDev)
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
