import { useEffect } from "react"
import { useSelector } from "react-redux"
import { history } from "../reducers/index"

export const useRequireDomainVariationOptions = () => {
    const {user, navigator} = useSelector((state) => state)
  
    useEffect(() => {
      if (!user.accessToken && !user.formData.url) {
        history.replace({
          pathname: '/sites',
          state: { nextPathname: navigator.location.pathname }
        });
      } else {
        if (user.accessToken && !user.formData.url) {
          history.replace({
            pathname: '/searchconsolesites',
            state: { nextPathname: navigator.location.pathname }
          });
        } else {
          if (!user.domainVariationOptions || user.domainVariationOptions.length === 0) {
            let path = '/searchconsole';
            if (!user.accessToken) path = '/siteinfo';
            history.replace({
              pathname: path,
              state: { nextPathname: navigator.location.pathname }
            });
          }
        }
      }
    }, [navigator.location.pathname, user.accessToken, user.domainVariationOptions, user.formData.url])
  }