import React from 'react'
import { APP_NAME, SUPPORT } from '../app.config'

const ManualProfiles = ({initialValues, GA4Configuration}) => {
  return (
    <div>
    <details className='text-[15px]'>
      <summary className='text-keylime-gray-2 italic mt-10 cursor-pointer'>How do I find the {GA4Configuration ? "property" : "view"} ID?</summary>
      <ol className='ml-6 mt-2 space-y-4'>
        <li>Once logged into Google Analytics, click <strong>Admin</strong>.</li>
        <li>Choose the account that contains {initialValues && initialValues.url}</li>
        <li>Choose the property that contains {initialValues && initialValues.url}
          <img src={'/images/GA-2.png'} alt="Google Analytics Screen Shot" className="img-responsive screenshot my-3" />
        </li>
      </ol>
      <div className=" space-y-4 mt-2">
        <p>Click the <strong>View</strong> menu to see the list of available views.
        </p>
        <p>The default view is generally called &#34;All Web Site Data&#34;. You might see additional views if they have been set up (and the default view name may have been changed to something else).</p>
        <p>You generally want to connect the default view to your {APP_NAME} account for best results. So look for the default view or a &#34;raw data&#34; view.</p>
        <p>If you aren&#39;t sure which view to connect, email us at <a href={`mailto:${SUPPORT}`} tabIndex="-1">{SUPPORT}</a></p>
        <p>Once you select the default view, click <strong>View Settings. </strong>
          <img src={'/images/GA-4.png'} alt="Google Analytics Screen Shot" className="img-responsive screenshot my-3" />
          The view ID is listed under Basic Settings.
          <img src={'/images/GA-5.png'} alt="Google Analytics Screen Shot" className="img-responsive screenshot my-3" />
        </p>
      </div>
    </details>
  </div>
  )
}

export default ManualProfiles