import { addGoogleUserMaybe, changeLocation, sendData } from '../actions/userActions'
import {history} from '../reducers/index';
import { submitForm as submit } from '../actions/userActions'; 

export const submitFailure = (fieldList) => (errors) => {
  // Field Error(s). We need to step through fieldList to guarantee ordering (since redux-form returns an errors object, not array or map)
  fieldList.find((field) => {
    if (errors[field] || (errors['wizard_options'] && errors['wizard_options'][field])) {
      const elem = document.querySelector(`input[name=${field}], input[name='wizard_options.${field}'], select[name=${field}]`);
      if (elem) {
        elem.focus();
        return true;
      }
    }
    // continue stepping through fieldList
    return false;
  });
};

export const submitForm = (values, dispatch) =>  {
  const {profileSelectGA3, ...updatedObject} = values
  const parseGA3Option = profileSelectGA3 && JSON.parse(profileSelectGA3.value)
  const GA4Option = {...updatedObject, ga_view: parseGA3Option ? parseGA3Option?.ga_view : updatedObject?.ga_view}

  return dispatch(submit(GA4Option)).then(({ value }) => {
    const returnUrl = value.returnUrl;
    if (value.nextPage === '/summary') {
      return Promise.all([
        dispatch(addGoogleUserMaybe(values)),
        dispatch(sendData(GA4Option)),
      ]).then(() => {
          dispatch(changeLocation()).then(({ value }) => {
          return history.push(`${returnUrl}/site_groups/${value}/summary`);
        });
      }).catch((response) => {
        if (response && response.error) {
          dispatch(changeLocation()).then(() => {
            return window.location = `${returnUrl}/summary?message=${response.error}`;
          });
        }
      });
    } else {
      return history.push(value.nextPage);
    }
  });
};
