import get from 'lodash/get';

export const isManual = (user) => {
  if (!user.accessToken || !user.sites) return true;
  return user.sites.filter((url) => url === user.formData.url).length === 0;
};

export const findTransitions = (currentPath, user) => {
  if (currentPath === "/searchconsolesites") return {to: '/settingsGoogleAnalytics', from: '/'};
  if (currentPath === "/settingsGoogleAnalytics") return {to: '/domainvariations', from: '/searchconsolesites'};
  if (currentPath === "/sites") return {to: '/siteinfo', from: '/'};
  if (currentPath === "/domainvariations") return {to: '/searchconsole', from: '/searchconsolesites'};
  if (currentPath === "/siteinfo") {
    let forward = '/searchconsoleadd';
    if (user.formData && user.formData.wizard_options && user.formData.wizard_options.searchConsoleVerified) forward = '/searchconsole';
    let back = '/sites';
    if (user.accessToken && !user.sites) back = '/searchconsolesites';
    return {to: forward, from: back};
  }
  if (currentPath === "/searchconsole") {
    let forward = '/summary';
    if (user.accessToken || (user.formData && user.formData.wizard_options && user.formData.wizard_options.googleAnalyticsInstalled)) {
      if (user.accessToken) {
        forward = '/gaprofiles';
      } else {
        forward = '/googleanalytics';
      }
    }
    
    let back = '/searchconsolesites';
    if (isManual(user)) {
      back = '/searchconsoleadd';
      if (user.formData && user.formData.wizard_options && user.formData.wizard_options.searchConsoleVerified) back = '/siteinfo';
    } else {
      if (user.domainVariationOptions && user.domainVariationOptions.length > 0) back = '/domainvariations';
    }
    return {to: forward, from: back};
  }
  if (currentPath === "/searchconsoleadd") return {to: '/searchconsole', from: '/siteinfo'};
  if (currentPath === "/googleanalytics") return {to: '/gaprofiles', from: '/searchconsole'};
  if (currentPath === "/profiles") return {to: '/summary', from: '/googleanalytics'};

  if (currentPath === "/gaprofiles" && get(user, 'formData.wizard_options.noGAForSite')) {
    return {to: '/summary', from: '/searchconsole'};
  }

  if (currentPath === "/gaprofiles") return {to: '/gaadduser', from: '/searchconsole'};
  if (currentPath === "/gaadduser") return {to: '/summary', from: '/gaprofiles'};
  return {to: '/summary', from: '/'};
};
