import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { googleLoginRequest } from '../actions/userActions'

const GoogleLogin = ({tag, text, img}) => {
    const dispatch = useDispatch();
    const {googleDisabled} = useSelector((state) => state?.user)

    const handleSubmit = () => {
        dispatch(googleLoginRequest())
    }

  return (
    <>
        {tag === 'button' 
            ?
            <button disabled={googleDisabled} className={`btn ${googleDisabled ? 'btn-primary-disabled' : 'btn-primary'} ${img ? 'flex mt-10 mb-11 pr-3' : ' btn-m'} `} onClick={handleSubmit}>
              {img && 
                <span className='bg-white h-full rounded-[4px] p-[7px] mr-2'>
                  <img
                    src={img}
                    className='w-[18px] h-[18px]'
                    alt='google-logo'
                    />
                </span>
              }
                {text}
            </button>
            :
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className='text-blue-900 text-[15px] font-semibold hover:underline w-fit' onClick={handleSubmit}>{text}</a>
            }
    </>
  )
}

export default GoogleLogin