import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { history } from "../reducers"

export const useRequireAccessTokenSites = () => {
    const dispatch = useDispatch()
  const {user} = useSelector((state) => state)
    useEffect(() => {
        if (!user.accessToken) {
              history.replace({
              pathname: '/sites',
              state: { nextPathname: '/domainvariations'}
            });
          }
    }, [dispatch, user.accessToken])
}
