import React from 'react'

const Card = ({children, title, tBody}) => {
  return (
    <div className='h-auto flex flex-col md:max-w-[320px] md: justify-between'>
        <div className="flex flex-col">
            <h3 className='h3'>{title}</h3>
            <p className='text-[15px]'>{tBody}</p>
        </div>
        { children }
    </div>
  )
}

export default Card