import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { manualSiteRequest } from '../actions/userActions'
import { APP_NAME } from '../app.config'
import Card from '../components/Card'
import GoogleLogin from '../components/GoogleLogin'
import { history } from '../reducers/index'

const HomePage = () => {
    const dispatch = useDispatch();
    const {accessToken, authEmail, group} = useSelector((state) => state?.user)

      const manualClick = (event) => {
       if(event) event.preventDefault();
       dispatch(manualSiteRequest()).then(
        ()=> {history.push('/sites')}
        )
      } 

  return (
    <div>
      <div className="mb-[9.5px]">
        {group ? 
          <h1 className='h1'>Add Sites to {group}</h1>
          :
          <h1 className='h1'>Add Your Site(s) To Your {APP_NAME} Account</h1>
        }
      </div>

      <div className='flex flex-col md:flex-row md:gap-20'>
        <Card
          title={'Sign In With Google'}
          tBody={`Sign in with a Google Account associated with the Google Search Console account that contains the first site you want to add to ${APP_NAME}.
          This enables ${APP_NAME} to automate some of your account configuration.`}
          >
            <div className="">
              { accessToken &&
                <p className="text-keylime-gray-2 flex flex-col">
                  SIGNED IN: {authEmail}
                    <br/>
                      <GoogleLogin
                        tag='a'
                        text="Sign in with Google to another account"
                      />
                </p>
              }
              <fieldset className="mt-2">
                    { accessToken ?
                      <Link className="btn btn-m btn-primary" to="/searchconsolesites" >Next</Link>
                      :
                      <GoogleLogin
                        tag='button'
                        text='Sign in with Google'
                        img='/images/google.svg'
                        />
                    }
                </fieldset>
            </div>
        </Card>

        <Card
          title={'Manual Set Up'}
          tBody={"Set up the Google Search Console and Google Analytics data for your site(s) manually. We'll walk you through it!"}
        >
          <fieldset>
              <a className="btn btn-m btn-primary mt-10 mb-11" href="/sites" onClick={(e) => manualClick(e)}
                   tabIndex="-1">Manual Set Up</a>
          </fieldset>
        </Card>
      </div>
    </div>
  )
}

export default HomePage