import { reset } from "redux-form-react18";
import 'regenerator-runtime';
import { getGAAccountSummaries, getGAAccountSummariesGA4, getGoogleSites } from "../actions/userActions";
import { CLIENT_ID } from "../app.config";
import * as types from "../constants/actionTypes";
import { history } from "../reducers/index";

export const generateHandleTokenResponse = function (dispatch, getState) {
  return async function(tokenResponse) {
      await dispatch({
        type: types.GOOGLE_LOGIN,
        payload: new Promise((resolve) => {
          resolve({
            accessToken: tokenResponse.access_token
          });
        }),
      });

      await Promise.all([
        dispatch(getGoogleSites(tokenResponse.access_token)).catch(() => {
        }),
        dispatch(getGAAccountSummaries(tokenResponse.access_token)).catch(() => {
        }),
        dispatch(getGAAccountSummariesGA4(tokenResponse.access_token)).catch(() => {
          
        })
      ]);

      const currentPath = getState().navigator.location && getState().navigator.location.pathname;
      if (currentPath !== "/profiles") {
        dispatch(reset("wizard"));
      }

      if (currentPath === "/") {
        return history.push("/searchconsolesites")
      }
      if (currentPath === "/profiles") {
        return history.push("/gaprofiles")
      }
  };
};

export const googleInit = function (dispatch, getState) {
  return new Promise(function (resolve, reject) {
    ((document, scriptTag, googleId,callbackAction) => {
      const element = document.getElementsByTagName(scriptTag)[0];
      const fjs = element;
      let js = element;
      js = document.createElement(scriptTag);
      js.id = googleId;
      js.src = "//accounts.google.com/gsi/client";
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = callbackAction;
    })(document, "script", "google-login", () => {
      const handleTokenResponse = generateHandleTokenResponse(dispatch, getState, resolve);

      const params = {
        client_id: CLIENT_ID,
        scope:
          "profile email https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.manage.users",
        ux_mode: "redirect",
        redirect_uri: "postmessage",
        cookiePolicy: "single_host_origin",
        fetchBasicProfile: true,
        offline: false,
        callback: (tokenResponse) => handleTokenResponse(tokenResponse),
      };
      window.client = window.google.accounts.oauth2.initTokenClient(params);
      const error_callback = window.client.error_callback;
      if (error_callback !== undefined) {
          reject({error: `Sorry, there was an error talking to Google. Here's the details: ${error_callback}`});
      }
      resolve("connected");
    });
  });
};

export const googleSignon = function () {
  const options = {
    redirect_uri: "postmessage",
    fetch_basic_profile: true,
    prompt: "select_account",
  };
  window.client.requestAccessToken(options);
};
