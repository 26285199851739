import React from 'react'
import Select from 'react-select';

const renderSelect = ({input, placeholder, meta: {touched, error, warning}, ...rest}) => {
  return (
    <div
        className={`${touched && error ? 'form-group has-error' : touched && warning ? 'form-group has-warning' : 'form-group'} min-h-[80px]`}>
      
        <Select
          autoFocus
          {...input}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
          {...rest}
        />
        <span className="text-keylime-red-1 text-[15px] mt-2">{touched && (error || warning)}</span>
      </div>
  )
}

export default renderSelect