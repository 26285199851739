import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className='flex flex-col'>
      <h4 className='h1'>
        404 Page Not Found
      </h4>
      <Link className='link my-5 w-fit' to="/"> Go back to homepage </Link>
    </div>
  );
};

export default NotFoundPage;
