import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom' 
import { history } from '../reducers/index'

const FormActions = ({disableNext}) => {
  const {user, backPage} = useSelector((state) => state)
  const {submitting} = useSelector((state) => state.form.wizard) || false

  const cancelUrl = user.returnUrl + '/site_groups/';
  return (
    <fieldset className="mt-10 mb-11">
        <div className="flex space-x-10 items-center">
          <button
            type="submit"
            className="btn btn-m btn-primary" disabled={disableNext || submitting || user?.changingLocation }>Next
          </button>
          <Link 
            className="link" 
            to={backPage}
            tabIndex="-1"
            onClick={()=>{ return history.back() }}
            disabled={submitting || !backPage || user?.changingLocation }>Back</Link>
          <Link 
            className="link"
            to={cancelUrl}
            tabIndex="-1"
            disabled={submitting || !backPage || user?.changingLocation }>Cancel</Link>
        </div>
      </fieldset>
  )
}

export default FormActions  