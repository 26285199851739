import {googleAuthorized, getSites, getAccountSummaries, keylimeSignout, addUser, validateGroup, addGroup, updateGroup, getAccountSummariesGA4} from '../api/ApiManager';
import {googleInit, googleSignon} from '../api/GoogleAuthManager';
import * as types from '../constants/actionTypes';
import isEmpty from 'lodash/isEmpty';
import {reset} from 'redux-form-react18';
import {keylimeReturnUrl, siteMatches, domainVariationMatches} from '../utils/validators';
import {findTransitions} from '../utils/routing';

export const clearState = () => (dispatch, getState) => {
  return dispatch({
    type: types.CLEAR_STATE,
    payload: new Promise((resolve) => {
      dispatch(reset('wizard'));
      resolve(getState().user.returnUrl);
    })
  });
};

export const getGoogleSites = (accessToken) => (dispatch) => {
  return dispatch({
    type: types.REQUEST_SITES,
    payload: new Promise((resolve, reject) => {
      getSites(accessToken).then((data) => {
        let sites = [];
        if (data && data.siteEntry) {
          sites = data.siteEntry.filter((s) => s.permissionLevel !== 'siteUnverifiedUser').map((s) => s.siteUrl).sort((a, b) => a.localeCompare(b));
        }
        if (sites.length > 0)
          resolve(sites);
        else {
          reject({error: {message: 'There are no Search Console Sites with this account. Enter a site manually or try signing in with another Google account.'}});
        }
      }).catch((response) => reject(response));
    })
  });
};

export const getGAAccountSummaries = (accessToken) => (dispatch) => {
  return dispatch({
    type: types.REQUEST_ACCOUNT_SUMMARIES,
    payload: getAccountSummaries(accessToken)
  });
};

export const getGAAccountSummariesGA4 = (accessToken) => (dispatch) => {
  return dispatch({
    type: types.REQUEST_ACCOUNT_SUMMARIES_GA4,
    payload: getAccountSummariesGA4(accessToken)
  });
}

export const setUser = (query, referrer) => (dispatch) => {
  return dispatch({
    type: types.SET_USER,
    payload: new Promise((resolve, reject) => {
      const returnUrl = keylimeReturnUrl(referrer);
      if (isEmpty(query)) {
        reject({ returnUrl: returnUrl });
      } else {
        const data = {
          returnUrl: returnUrl,
          authenticityToken: (new URLSearchParams(query)).get("a"),
          name: (new URLSearchParams(query)).get("n"),
          group: (new URLSearchParams(query)).get("g"),
          groupSlug: (new URLSearchParams(query)).get("gs"),
        };
        dispatch(clearState()).then(() => {
          resolve(data);
        });
      }
    }),
  });
};

export const googleInitialize = () => (dispatch, getState) => {
  return dispatch({
    type: types.GOOGLE_INIT,
    payload: new Promise((resolve, reject) => {
      googleInit(dispatch, getState)
        .then(resolve)
        .catch((reason) => {
          setTimeout(() => dispatch(googleInitialize()));

          reject(reason);
        });
    }),
  });
};

export const googleLoginRequest = () => () => {
  googleSignon();
};

export const logoutRequest = () => (dispatch, getState) => {
  return dispatch({
    type: types.LOGOUT_REQUEST,
    payload: Promise.all([
      dispatch({
        type: types.KEYLIME_LOGOUT,
        payload: keylimeSignout(getState().user.returnUrl)
      }),
      dispatch(clearState())
    ])
  });
};

export const manualSiteRequest = () => (dispatch) => {
  return dispatch({
    type: types.MANUAL_SITE_REQUEST,
    payload: new Promise((resolve) => {
      dispatch(reset('wizard'));
      resolve();
    })
  });
};

export const setTransitions = () => (dispatch, getState) => {
  return dispatch({
    type: types.SET_TRANSITIONS,
    payload: new Promise((resolve) => {
      const currentPath = getState().navigator?.location && getState().navigator.location.pathname;
      const transitions = findTransitions(currentPath, getState().user);
      resolve({returnUrl: getState().user.returnUrl, nextPage: transitions.to, backPage: transitions.from});
    })
  });
};

export const setDomainVariations = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.SET_DOMAIN_VARIATION_OPTIONS,
    payload: new Promise((resolve) => {
      const user = getState().user;
      const domainVariationOptions = user.sites.filter((url) => url !== formValues.url && siteMatches(url, formValues.url));
      const domainMatches = user.sites.filter((url) => url !== formValues.url && domainVariationMatches(url, formValues.url));
      resolve({domainVariationOptions: domainVariationOptions, domainMatches: domainMatches});
    })
  });
};

export const submitForm = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.SUBMIT_FORM,
    payload: new Promise((resolve) => {
      const user = getState().user;
      if (user.sites && formValues.url) {
        dispatch(setDomainVariations(formValues)).then(() => resolve(formValues));
      } else {
        resolve(formValues);
      }
    }),
  }).then(() => dispatch(setTransitions()));
};

export const checkGoogleAuthorized = (accessToken) => (dispatch) => {
  return dispatch({
    type: types.CHECK_GOOGLE_AUTHORIZED,
    payload: new Promise((resolve,reject) => {
      googleAuthorized(accessToken).then((response) => resolve(response)).catch((response) => reject(response));
    })
  });
};

export const addGoogleUser = (accessToken, formValues) => (dispatch) => {
  return dispatch({
    type: types.ADD_GA_USER,
    payload: new Promise((resolve,reject) => {
      addUser(accessToken, formValues.ga_account_id, formValues.ga_property_id, formValues.ga_view, formValues).then((response) => resolve(response)).catch((response) => reject(response));
    })
  });
};

export const addGoogleUserMaybe = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.TRY_ADD_USER,
    payload: new Promise((resolve) => {
      const user = getState().user;
      const hasViewOrPropertyId = formValues.ga_property_id || formValues.ga_view;

      if (user.accessToken &&
        formValues.wizard_options &&
        formValues.wizard_options.googleAnalyticsUserAdded === "yes" &&
        formValues.ga_account_id &&
        hasViewOrPropertyId) {
        dispatch(checkGoogleAuthorized(user.accessToken))
          .then(() => {
            dispatch(addGoogleUser(user.accessToken, formValues)).then(() => resolve()).catch(() => resolve());
          })
          .catch(() => resolve());
      } else {
       resolve();
      }
    })
  });
};

export const validateReportingGroup = (url) => (dispatch, getState) => {
  return dispatch({
    type: types.VALIDATE_REPORTING_GROUP,
    payload: new Promise((resolve,reject) => {
      const user = getState().user;
      validateGroup(user.returnUrl, url).then((response) => resolve(response)).catch((error) => reject(error));
    })
  });
};

export const addReportingGroup = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.ADD_REPORTING_GROUP,
    payload: new Promise((resolve,reject) => {
      const user = getState().user;
      addGroup(user.returnUrl, formValues, user.domainMatches).then((response) => resolve(response)).catch((error) => reject(error));
    })
  });
};

export const updateReportingGroup = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.UPDATE_REPORTING_GROUP,
    payload: new Promise((resolve,reject) => {
      const user = getState().user;
      updateGroup(user.returnUrl, user.groupSlug, formValues, user.domainMatches).then((response) => resolve(response)).catch((error) => reject(error));
    })
  });
};

export const sendData = (formValues) => (dispatch, getState) => {
  return dispatch({
    type: types.SEND_DATA,
    payload: new Promise((resolve,reject) => {
      const user = getState().user;
      if (user.groupSlug) {
        dispatch(updateReportingGroup(formValues)).then(() => resolve()).catch(() => reject());
      } else {
        dispatch(addReportingGroup(formValues)).then(() => resolve()).catch(() => reject());
      }
    })
  });
};

export const clearError = () => (dispatch) => {
  return dispatch({
    type: types.CLEAR_ERROR,
    payload: new Promise(resolve => resolve())
  });
};

export const changeLocation = () => (dispatch ,getState) => {
  return dispatch({
    type: types.CHANGE_LOCATION,
    payload: new Promise(resolve => resolve(getState().user.groupSlug))
  });
};
