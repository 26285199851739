import React from 'react'
import { APP_NAME, APP_URL, SUPPORT } from '../app.config'

const Footer = () => {
    const now = new Date();
  return (
    <footer id='footer' className='w-full bg-keylime-text min-h-[52px] px-4 md:pl-[14%] fixed bottom-0 z-50 '>
        <div className='flex flex-col h-full'>
            <div className="text-white text-[11px] h-full flex flex-col my-2">
              <a className="text-[11px] hover:underline" href={`mailto:${SUPPORT}`} tabIndex="-1">{SUPPORT}</a>
              <div className=" text-white  text-[11px]">
                 © {now.getFullYear()} {APP_NAME} | <a className='text-[11px] text-white hover:underline' href={`${APP_URL}/terms_of_service`} tabIndex="-1">Terms of Service</a>
              </div>
            </div>
          </div>
    </footer>
  )
}

export default Footer