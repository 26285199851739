import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../reducers/index';

const useCheckAccessToken = () => {
    const {user, navigator} = useSelector((state) => state)

    useEffect(() => {
        if (user.accessToken) {
            history.replace({
              pathname: '/gaprofiles',
              state: { nextPathname: navigator.location.pathname }
            });
          }
    }, [navigator.location.pathname, user.accessToken])
    
}

export default useCheckAccessToken