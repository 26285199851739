import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { change, Field, reduxForm, reset } from 'redux-form-react18'
import { APP_NAME, SUPPORT } from '../app.config'
import FormActions from '../components/FormActions'
import GoogleLogin from '../components/GoogleLogin'
import ManualProfiles from '../components/ManualProfiles'
import renderField from '../components/renderField'
import renderSelect from '../components/renderSelect'
import { useRequireAccessTokenProfiles } from '../hooks/useRequireAccessTokenProfiles'
import { useRequireAccountSummaries } from '../hooks/useRequireAccountSummaries'
import { useRequireSelectedSite } from '../hooks/useRequireSelectedSite'
import { history } from '../reducers/index'
import { submitForm } from '../utils/submitUtils'
import { number } from '../utils/validators'


const GAProfiles = ({handleSubmit}) => {
    useRequireSelectedSite()
    useRequireAccountSummaries()
    useRequireAccessTokenProfiles()

    const dispatch = useDispatch()

    const [ga3Selected, setGA3Selected] = useState(false)

    const { accountSummaries, formData, authEmail, accountSummariesG4 } = useSelector((state) => state?.user)
    const GA3Installed = useSelector((state) => state?.form?.wizard?.values?.wizard_options?.GA3Installed) || false
    const GA4Installed = useSelector((state) => state?.form?.wizard?.values?.wizard_options?.GA4Installed)

    const submit = (values) => {
      const {profileSelectGA3, ...updatedObject} = values
      const parseGA3Option = ga3Selected && JSON.parse(ga3Selected.value)
      
      const GA4Option = GA4Installed ? {...updatedObject, ga_view: ga3Selected ? parseGA3Option?.ga_view : null} : values

      submitForm(GA4Option, dispatch);
    }

    const manualClick = (event) => {
        if (event) event.preventDefault();
        reset('wizard')
        return history.push('/profiles');
      };

      const selectManualProfile = (value) => {
        dispatch(change('wizard', 'profileSelect', value))
        dispatch(change('wizard', 'ga_account_id', value))
      };

      const selectProfile = (selected) => {
        const value = selected && selected.value ? selected.value : null;
        const p = value ? JSON.parse(value) : {};

        dispatch(change('wizard', `wizard_options.noGAForSite`,false))
        dispatch(change('wizard', `ga_account_id`,p.ga_account_id||null))
        dispatch(change('wizard', `ga_property_id`,p.ga_property_id||null))
        dispatch(change('wizard', `ga_view`,p.ga_view||null))
      };

      const profileGA4Options = (accountSummariesG4 || []).flatMap((account) =>
      (account.propertySummaries || []).map((property) => ({
        value: `{"ga_account_id": "${account.account.replace("accounts/", "")}", "ga_property_id": "${property.property.replace("properties/", "")}", "ga_view": ""}`,
        label: `${account.displayName} ${property.displayName}`
      })));

      const profileUniversalOptions= (accountSummaries || []).flatMap((account) =>
      (account.webProperties || []).flatMap((property) =>
        (property.profiles || []).map((profile) => ({
          value: `{"ga_account_id": "${account.id}", "ga_property_id": "${property.id}", "ga_view": "${profile.id}"}`,
          label: `${account.name} (${property.websiteUrl}) ${profile.name}`
    }))));

    const selecteOption = 
      GA4Installed === true && GA3Installed === true ? "GA4 and GA3" :
      GA4Installed !== true && GA3Installed === true  ? "GA3" :
      GA4Installed === true && GA3Installed !== true ? "GA4" : "GA4 and GA3"

    const selectGA3FormValues = (e) => {
      if (selecteOption.includes("GA4")) return setGA3Selected(e);
      selectProfile(e)
      setGA3Selected(e)
    }

  return (
      <form className='flex flex-col' onSubmit={handleSubmit(submit)}>
        <h1 className='h1'>Add Google Analytics :{selecteOption}</h1>
        <h3 className='h3'>{formData && formData.url}</h3>
        {accountSummaries ?
          <p className='simple-text'>Choose the Google Analytics profile you&#39;d like to associate with this site in {APP_NAME}. We recommend
            connecting the default, unfiltered {selecteOption === "GA4"  ? "profile" : selecteOption === "GA4" ? "view" : "profile or view"} (if one exists).</p>
          :
          <p className='simple-text'>Add the property and Property ID that you want to import into your {APP_NAME} account. We generally recommend adding the default/raw view.
            If you&#39;re unsure which view is best, just email us at <a className="navbar-link" href={`mailto:${SUPPORT}`} tabIndex="-1">{SUPPORT}</a> and we&#39;ll help you out.
            <br/><br/>
            If you can&#39;t add this data now, you can come back to your account and add it later.
          </p>
        }
       
        {accountSummaries ?
          <>
           {GA4Installed === true &&
              <>
                <h3 className='h3'>GA4 Options</h3>
                <fieldset>
                  <Field
                    name={`${selecteOption.includes("GA4") ? "profileSelect" : ""}`}
                    component={renderSelect}
                    options={profileGA4Options}
                    onChange={(e) => selectProfile(e)}
                  />
                  <Field
                    name="ga_account_id"
                    component={renderField}
                    type="hidden"
                  />
                  <Field
                    name="ga_property_id"
                    component={renderField}
                    type="hidden"
                  />
                  <Field
                    name="ga_view"
                    component={renderField}
                    type="hidden"
                  />
                </fieldset>
              </>
            }
            {GA3Installed === true &&
              <>
                <h3 className='h3'>Universal Options</h3>
                <fieldset>
                  <Field
                    name={`${selecteOption.includes("GA4") ? "profileSelectGA3" : "profileSelect"}`}
                    component={renderSelect}
                    options={profileUniversalOptions}
                    onChange={(e) => selectGA3FormValues(e)}
                  />
                  <Field
                    name="ga_account_id"
                    component={renderField}
                    type="hidden"
                  />
                  <Field
                    name="ga_property_id"
                    component={renderField}
                    type="hidden"
                  />
                  <Field
                    name="ga_view"
                    component={renderField}
                    type="hidden"
                  />
                </fieldset>
              </>
            }
          </>
          :
          <>
          <fieldset>
            <Field
              name="ga_view"
              component={renderField}
              type="text"
              label="Property ID"
              validate={number}
              autoFocus={false}
              onChange={(e) => selectManualProfile(e)}
            />
            <Field
              name="profileSelect"
              component={renderField}
              type="hidden"
            />
            <Field
              name="ga_property_id"
              component={renderField}
              type="hidden"
            />
            <Field
              name="ga_account_id"
              component={renderField}
              type="hidden"
            />
          </fieldset>
          </>
        }
        <p className="mb-5 text-keylime-gray-2 flex flex-col space-y-1">
          SIGNED IN: {authEmail}
          <br/>
          {accountSummaries && "Don't see your property listed? " }
          <GoogleLogin
            tag="a"
            text="Sign in with Google to another account"
            className={'link'}
          />
          {accountSummaries &&
            <span>
              { " " }
              or enter it 
              <a
                className="link ml-1"
                href="/profiles"
                onClick={(e) => manualClick(e)}
                tabIndex="-1"
              >
                manually.</a>
            </span>
          }
        </p>
        {!accountSummaries && <ManualProfiles initialValues={formData} />}
        <FormActions />
      </form>
  )
}

export default reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
    // onSubmitFail: submitFailure(['ga_property_id','ga_view'])
  })(GAProfiles);