import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { setUser } from "../actions/userActions"
import { history } from "../reducers"

export const useAuthAccount = () => {
    const dispatch = useDispatch();
    const {user, navigator} = useSelector((state) => state)

    useEffect(() => {
      if (!user.authenticityToken || !isEmpty(navigator.location.search)) {
        dispatch(setUser(navigator.location.search, document.referrer)).then(() => {
          return history.push('/');
        }).catch(({returnUrl}) => {
          return history.push(`${returnUrl}/welcome`)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    
}