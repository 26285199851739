import {parseDomain, fromUrl} from 'parse-domain';
import {DOMAIN_TLD, APP_URL} from '../app.config';
import Url from 'url-parse';
export const required = value => value ? undefined : 'Required';
export const url = value => {
  if (value && value.value) value = value.value;
  return value && !/(https?|android-app|sc-set|sc-domain):(\/\/)?.+/.test(value) ?
    'Invalid URL. The URL should follow the format: https://www.example.com' : undefined;
};
export const number = value => {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};
export const keylimeReturnUrl = (url) => {
  if (url) {
    const a = document.createElement('a');
    a.href = url;
    const hostname = `${a.hostname}`;
    if (hostname) {
      const domainTldReg = new RegExp('\\.' + DOMAIN_TLD + '$');
      if (domainTldReg.test(hostname) || hostname === 'localhost') {
        return `${a.protocol}//${a.host}`;
      }
    }
  }
  return APP_URL;
};

export const siteMatches = (value, url) => {

  // If an apex site (example.com) also look for www.example.com
  // If a www site, also look for the apex site (example.com)
  // If http, also look for https (including apex/www variations if applicable)
  // If https, also look for http (including apex/www variations if applicable)
  // Look for subfolders (/${url}\/._/ as a regex; i.e. anything that matches the URL with a slash and trailing something)
  const parseDomainUrl = parseDomain(Url(url));
 
  if (parseDomainUrl == null || parseDomainUrl === undefined) return false;
  const parsedUrl = `${parseDomainUrl['domain']}.${parseDomainUrl['topLevelDomains']?.join(".")}`;

  const parseDomainValue = parseDomain(Url(value).pathname);
  if (parseDomainValue == null || parseDomainValue === undefined) return false;
  const parsedValue = `${parseDomainValue['domain']}.${parseDomainUrl['topLevelDomains']?.join(".")}`;

  if(parsedUrl === parsedValue && (parseDomainUrl["subdomain"] === parseDomainValue["subdomain"] || parseDomainValue["subdomain"] === "" || parseDomainValue["subdomain"] === "www")) {
    let link = document.createElement('a');
    link.href = url;
    const urlPath = link['pathname'];

    if (urlPath.length > 1) {
      link.href = value;
      return link['pathname'].search(urlPath) > -1;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const domainVariationMatches = (value, url) => {
  const parseDomainUrl = parseDomain(fromUrl(url));
  if (parseDomainUrl == null) return false;

  const parseDomainValue = parseDomain(fromUrl(value));
  if (parseDomainValue == null) return false;

  return parseDomainUrl['domain'] === parseDomainValue['domain'];
};
