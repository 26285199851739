import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { googleInitialize, logoutRequest } from '../actions/userActions'
import { APP_NAME } from '../app.config'

const Navbar = () => {
    const dispatch = useDispatch();
    const { returnUrl } = useSelector((state) => state.user)
    const { user } = useSelector((state) => state)
    const [openMoreOptions, setOpenMoreOptions] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
      dispatch(googleInitialize())
    }, [dispatch])

    const signOut = () => {
      dispatch(logoutRequest()).then(() => {
        return window.location = `${user.returnUrl}/users/sign_in`;
      }).catch(() => {
        return window.location = `${user.returnUrl}/users/sign_in`;
      });
    };

    return (
      <div className='w-full flex flex-col'>
        <div className="w-full bg-keylime-green-1 h-16 md:bg-keylime-gray-1 md:h-10 fixed flex top-0">

          {/* mobile */}
          <div className="flex md:hidden relative w-creen">
            <div className="px-4 items-center justify-between w-screen flex">
              <img 
                alt={APP_NAME}
                src={'/images/keylime_logo_white.svg'}
                className='top-0 left-6 h-[50px] w-auto md:hidden flex'
              />
              <button
                onClick={()=> setOpenModal(!openModal)}
                className="text-white py-[10px] px-[12px] hover:bg-keylime-green-3 rounded-[4px] flex">
                  <img src="/images/ham-icon.png" alt="ham-icon " className='w-5' />
              </button>
            </div>

                  {/* mobile dropdown */}
              <div className={`border-t absolute border-black p-4 bg-keylime-green-1 w-full top-16 h-auto flex text-black md:hidden ${openModal ? 'flex flex-col' : ' hidden'}`}>
                <a href={`${returnUrl}/summary`} className='nav-button '>
                  <img src="/images/home-icon.png" alt="home-icon " className='h-5 w-5' />
                </a>
                <button 
                  onClick={()=> setOpenMoreOptions(!openMoreOptions)}
                  className={` ${openMoreOptions && 'bg-keylime-green-3'} nav-button flex items-start `}>
                    {user.name}
                    <img src="/images/dropdown-icon.png" alt="dropdown-icon " className='h-4 w-4 flex mt-1 ml-1' />
                </button>
                <div className={`${openMoreOptions ? 'flex pl-10 nav-button' : ' hidden'}`}>
                  <button onClick={signOut}>
                    Sign Out
                  </button>
                </div>
                <a target='__blank' href={`${returnUrl}/settings/account`} className='nav-button'>
                  Settings
                </a>
                <a target='__blank' href={`${returnUrl}/faqs`} className='nav-button font-black text-xl'>
                  ?
                </a>
              </div>
          </div> 




          {/* desktop */}
          <div className="hidden md:flex ml-auto">
            <img 
              alt={APP_NAME}
              src={'/images/keylime_logo.svg'}
              className='absolute top-6 left-6 h-[73px] w-auto hidden md:flex'
              />
            <div className="flex w-full">
              <a href={`${returnUrl}/summary`} className='nav-button'>
                <img src="/images/home-icon-gray.png" alt="home-icon " className='h-5 w-5' />
              </a>
              <button onClick={()=> setOpenMoreOptions(!openMoreOptions)} className={`flex justify-center items-center nav-button ${openMoreOptions && 'bg-white text-keylime-green-1'}  `}>
                {user.name}
              <img src="/images/dropdown-icon-gray.png" alt="dropdown-icon " className='h-[12px] w-[12px] flex justify-center mt-1 ml-1' />
              </button>
              <a target='__blank' href={`${returnUrl}/settings/account`} className='nav-button text-center flex flex-col justify-center'>
                Settings
              </a>
              <a target='__blank' href={`${returnUrl}/faqs`} className='nav-button font-black text-xl text-center flex flex-col justify-center'>
                ?
              </a>
            </div>

            {/* More options logout desktop */}
              <ul className={`${openMoreOptions ? 'absolute' : ' hidden'}  w-[160px] h-[38px] rounded-[4px] border border-keylime-gray-1 top-10 right-[120px] shadow-xl`}>
                <li className="ml-[30px] flex my-2 text-[15px] cursor-pointer" onClick={signOut}>Sign Out</li>
              </ul>

          </div>
        </div>


       
        </div>
      );
}

export default Navbar