import { Notifier } from '@airbrake/browser';
import {AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY} from '../app.config';

let airbrakeClient;

const initializeAirbrake = () => {
  if (process.env.NODE_ENV === 'test') return;

  if (!airbrakeClient) {
    airbrakeClient = new Notifier({
      projectId: AIRBRAKE_PROJECT_ID,
      projectKey: AIRBRAKE_PROJECT_KEY,
      environment: process.env.NODE_ENV
    });
    airbrakeClient.addFilter(notice => {
      notice.context.environment = process.env.NODE_ENV;
      if (process.env.NODE_ENV === 'production') {
        return notice;
      } else {
        return null;
      }
    });
  }
};

export const notify = (err = {}) => {
  initializeAirbrake();
  airbrakeClient.notify({ ...err });
};

export default function airbrakeMiddleware(notify = {}) {
  initializeAirbrake();

  const airbrakeLog = (error, params, user) => {
    airbrakeClient.addFilter(notice => {
      notice.params = { ...(notice.params || {}), ...params };
      notice.session = { ...(notice.session || {}), ...user };
    });
    airbrakeClient.notify({ ...notify, error });
  };

  return store => next => action => {
    try {
      return next(action);
    } catch (error) {
      const params = {
        action: action
      };
      const user = store.getState().user;
      airbrakeLog(error, params, user);
      return error;
    }
  };
}
