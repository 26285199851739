import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form-react18'
import { validateReportingGroup } from '../actions/userActions'
import { APP_NAME } from '../app.config'
import AlertMessage from '../components/AlertMessage'
import FormActions from '../components/FormActions'
import renderField from '../components/renderField'
import { submitFailure, submitForm } from '../utils/submitUtils'
import { required, url } from '../utils/validators'

const Sites = ({handleSubmit}) => {
    const dispatch = useDispatch()
    const {group, returnUrl} = useSelector((state) => state?.user)

    const submit = (values) => {
        dispatch(validateReportingGroup(values.url))
        .then(() => submitForm(values, dispatch))
        .catch((error) => {
          if (error.status > 400) return window.location = `${returnUrl}/users/sign_in`;
        });
      };

    const selectSite = () => {
      dispatch(change('wizard','siteSelect',null));
      };
      
  return (
    <form className='max-w-[800px] flex flex-col' onSubmit={handleSubmit(submit)}>
      {group ?
        <h1 className='h1'>Add a Site to Aggregate with Data from {group}</h1>
        :
        <h1 className='h1'>Add a Site To Your {APP_NAME} Account</h1>
      }
      <p className='text-base mt-2 mb-5'>Add the site as it resolves (for instance, with http or https, with www or without).</p>
      <AlertMessage />
      <Field name="url"
             component={renderField}
             placeholder="https://www.example.com"
             type="text"
             Label="Site URL"
             validate={[required, url]}
             onChange={(e) => selectSite(e)}
      />
      <Field name="siteSelect"
             component={renderField}
             type="hidden"
      />
      <FormActions />
    </form>
  )
}

export default  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,        // <------ preserve form data
    forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
    onSubmitFail: submitFailure(['url'])
  })(Sites);